const enJSON = {
  "Sign in": "Login",
  "Fill in the fields below to sign into your account.":
    "Fill in the fields below to sign into your account.",
  "Don’t have an account, yet?": "Don’t have an account, yet?",
  "Sign up here": "Sign up here",
  "The email provided should be a valid email address":
    "O email fornecido precisa se validado.",
  "The email field is required": "O campo email é requerido",
  "The password field is required": "O campo password é requerido",
  "You must agree to our terms and conditions":
    "Você deve aceitar os termos e condições",
  "I accept the": "I read and accept the",
  "terms and conditions": "termos e condições",
  "Lost password?": "Esqueceu o password?",
  "Email address": "Endereço de Email",
  Password: "Password",
  "Create your account": "Criar a sua conta",
  Name: "Nome",
  "Already have an account?": "Você já tem uma conta?",
  "Sign in here": "Realize o Login aqui",
  "Create account": "Crie sua conta",
  "Fill in the fields below to sign up for an account.":
    "Preencha os campos abaixo para criar sua conta",
  "Want to try to sign in again?": "Quero tentar logar novamente",
  "Click here": "Clique aqui",
  "Enter the email used for registration to reset your password.":
    "Informe o email usado no processo de registro, para resetar seu password.",
  "Send me a new password": "Me envie um novo password",
  "The password reset instructions have been sent to your email":
    "Caso esteja cadastrado, um email com as instruções será enviado.",
  "Check your email for further instructions": "Verifique seu email",
  "Continue to login": "Proceda para o login",
  "Wrong credentials or incomplete prerequisites.":
    "Credenciais incorretas ou Pré-requisitos incompletos.",
  Learning: "Courses",
  Challenges: "Challenges",
  "learning points earned": "Pontos de Aprendizado",
  Courses: "Courses",
  "Use this page to manage your products , the fast and easy way.":
    "Afie seu conhecimento com os cursos abaixo.",
  "We couldn't find any products matching your search criteria":
    "Não foi possível encontrar cursos com seus critérios de pesquisa",
  "Product name": "Nome do Curso",
  Price: "Preço",
  Students: "Estudantes",
  Categories: "Categorias",
  "Product Details": "Detalhes Curso",
  "Challenge yourself and learn.": "Desafie-se e acelere seu aprendizado",
  "Search by course name...": "Pesquise pelo nome do curso...",
  "Search by challenge name...": "Pesquise pelo nome do desafio...",
  Plataform: "Plataforma",
  "Extreme Hacking": "Extreme Hacking",
  "High performance plataform":
    "Querendo entrar no mundo de cybersegurança, ou até mesmo melhorar seus conhecimentos com desafios inovadores, tanto na área Red Team quanto Blue Team. Seja bem vindo, aqui é local para você.",
  Explore: "Explore a Plataforma",
  "Dashboard Menu Header": "Menu rápido para os conteúdos",
  "Construindo: ": "Building: ",
  Send: "Enviar",
  Challenge: "Challenge",
  "Find the plan that suits you best": "Find the plan that suits you best",
  "Choose your plan": "Choose your plan",
  month: "month",
  year: "year",
  Features: "Features",
  Monthly: "Monthly",
  Annual: "Annual",
  "Assinando Agora você ganha 20% de desconto no primeiro mês":
    "By subscribing now you get 20% off the first month",
  "Select Plan": "Select Plan",
  month: "month",
  year: "year",
  Features: "Features",
  Monthly: "Monthly",
  Annual: "Annual",
  "Assinando Agora você ganha 20% de desconto no primeiro mês":
    "By subscribing now you get 20% off the first month",
  "Select Plan": "Select Plan",
  "Thank you for your purchase!": "Thank you for your purchase!",
  "You will receive a confirmation email shortly!":
    "You will receive a confirmation email shortly!",
  "Your request has been sent to the team captain":
    "Your request has been sent to the team captain",
  "Additional Informations": "Additional Informations",
  Reviews: "Reviews",
  "Challenge Details": "Challenge Details",
  "total challenges": "total challenges",
  "Generate VPN Profile": "Generate VPN Profile",
  "VPN Server": "VPN Server",
  "Download here to connect to the VPN": "Download here to connect to the VPN",

  "Courses Enrolled Completed": "Courses Enrolled Completed",
  Points: "Points",
  "Points to ": "Points to ",
  "Challenges Accepted Completed": "Challenges Accepted Completed",
  Achieved: "Achieved",
  "First Blood Achieved":
    "Number of times you were the first to solve a challenge or quiz",
  "Course points": "Course points",
  "Challenges points": "Challenges points",
  "My Ranking": "My Ranking",
  Leaderboard: "Top 10",
  Ranking: "Rank",
  Member: "Member",
  "Points to reach the next level": "Points to reach the next level",
  "Percentual of courses completed": "Percentual of courses completed",
  "Percentual of challenges completed": "Percentual of challenges completed",
  "Total points earned from courses and challenges":
    "Total points earned from courses and challenges",
  "Total points earned from courses": "Total points earned from courses",
  "Total points earned from challenges": "Total points earned from challenges",
  "Find your challenge, and learn something new!":
    "Choose your challenge to learn something new!",
  level: "Level",
  Level: "Level",
  "Level 1-5": "Level 1-5",
  "Level 5-1": "Level 5-1",
  "Title A-Z": "Title A-Z",
  "Title Z-A": "Title Z-A",
  "Completed A-Z": "Completed A-Z",
  "Completed Z-A": "Completed Z-A",
  title: "Title",
  "Sort by...": "Sort by...",
  Showing: "Showing",
  of: "of",
  challenges: "challenges",
  "Unlock Your Potential": "Solve Now",
  "Rows per page": "Elements per page",
  "Search Challenges": "Search cybersecurity challenges with AI",
  Verifying: "Verifying",
  Running: "Running",
  Stopped: "Stopped",
  "Pronto Para Iniciar": "Ready To Start",
  "Parando...": "Stopping...",
  "Inicializando...": "Initializing...",
  "Realize o Download do Arquivo para encontrar a Flag":
    "Download the File to find the Flag",
  Submit: "Submit",
  "Thanks for your review!": "Thanks for your review!",
  "Students Reviews": "Reviews",
  "Leave your review about the Challenge":
    "Leave your review about the Challenge",
  Categories: "Categories",
  "I accept the": "I read and accept the",
  "I accept this Challenge": "Dare to Take it On and Push Your Limits!",
  "Access to Lab here": "Access to Lab here",
  Expires: "Expires in",
  "My Learning": "My Learning",
  "My Courses and Challenges!": "My Courses and Challenges!",
  "Sort by...": "Sort by...",
  "Search Courses or Challenges": "Search Courses or Challenges",
  "Matricule-se no curso.": "Enroll in the course.",
  "Conteúdo do curso": "Course Content",
  Outcomes: "Outcomes",
  Content: "Content",
  Enroll: "Enroll",
  "Course Details": "Course Details",
  Hint: "Hint",
  "The tips come at a cost.": "The tips come at a cost.",

  "This tip will cost": "This tip will cost",
  "Não quero a dica": "I don't want the tip",
  "I Accept": "I Accept",

  "Enjoy your tip": "Enjoy your tip",

  "Congratulations! Correct answer": "Congratulations! Correct answer",
  "Opss! this is not the correct answer":
    "Opss! this is not the correct answer",
  Answer: "Answer",
  "Previous Content": "Previous Content",
  "Complete and Continue": "Complete and Continue",
  "Your download is ready!": "Your download is ready!",
  "Você Finalizou o último Módulo do Curso. Parabéns!":
    "Congratulations! You have completed the last module of the course.",
  "Leave your review about the Course.": "Leave your review about the Course.",
  courses: "Courses",
  "Search Courses": "Search Courses",
  "Find your Course": "Find your Course",
  "Start to Learn Now": "Start to Learn Now",
  "CTF Events": "CTF Events",
  "Let's having fun!": "Let's having fun!",
  Thanks: "Thanks",
  "Search CTF": "Search CTF Events",
  "total Events": "total Events",
  "Inscreva-se": "Subscribe",
  "Thanks you for participating": "Thanks you for participating!",
  "Remaining Time": "Remaining Time",
  Prizes: "Prizes",
  Audience: "Audience",
  Organization: "Organization",
  "Visit Website": "Visit Website",
  Sponsor: "Sponsor",
  "Signup the CTF": "Signup the CTF",
  "Organizated by": "Organizated by",
  "Only Captains can signup the team": "Only Captains can signup the team",
  "Bellow options are the teams that you are captain":
    "Bellow options are the teams that you are captain",
  Cancel: "Cancel",
  Save: "Save",
  "Back to CTF Events": "Back to CTF Events",
  Teams: "Teams",
  "CTF Teams": "CTF Teams",
  "Create Team": "Create Team",
  "Fill in the fields below to create a Team":
    "Fill in the fields below to create a Team",
  "The name field is required": "The name field is required",
  "The lema name field is required": "The lema name field is required",
  "The Team has been removed": "The Team has been removed",
  "Erro to remove Team": "Erro to remove Team",
  "Your request has been sent to the team captain":
    "Your request has been sent to the team captain",
  "We couldn't find any teams matching your search criteria":
    "We couldn't find any teams matching your search criteria",
  Name: "Name",
  Captain: "Captain",
  Players: "Players",
  "Ask to Join": "Ask to Join",
  "Are you sure you want to permanently delete this Team":
    "Are you sure you want to permanently delete this Team",
  Delete: "Delete",
  "User - Profile": "User - Profile",
  "Back to Teams": "Back to Teams",
  Details: "Details",
  Role: "Role",
  "Join Requests": "Join Requests",
  Settings: "Settings",
  "Manage informations related to your Team":
    "Manage informations related to your Team",
  "Team Settings": "Team Settings",
  "Any Join Request found": "Any Join Request found",
  Profile: "Profile",
  "Edit Profile": "Edit Profile",
  "Access Logs": "Access Logs",
  "Recent sign in activity logs": "Recent sign in activity logs",
  "I don't want the tip": "I don't want the tip",
  "Enjoy your tip": "Enjoy your tip",
  True: "True",
  False: "False",
  "Execute the task and click Complete": "Execute the task and click Complete",
  Complete: "Complete",
  Theme: "Theme",
  "Profile settings": "Profile Settings",
  "PowerOn/PowerOff": "PowerOn/PowerOff",
  'Revert': 'Revert',
  "Challenge IP": "Challenge IP",
  "Access ExtremeBox here": "Access ExtremeBox here",
  "Em Breve": "Coming Soon",
  "Ranking_board": "Ranking",
  "Team": "Team",
  "The tips come at a cost.": "The tips come at a cost.",
  "This tip will cost": "This tip will cost",
  "I don't want the tip": "I don't want the tip",
  "Enjoy your tip": "Enjoy your tip",
  "This tip will cost": "This tip will cost",
  "Event has ended." : "Event has ended.",
  "Register": "Sign Up",
  "Regras": "Rules",
  "User": "User",
  "Registered at the event with": "Registered at the event with",
  "Start Date": "Start Date",
  "End Date": "End Date",
  "Revert the machine to prestine state": "Revert the machine to prestine state",
  'Birth date': 'Birth date',
  'Birth date is required': 'Birth date is required',
  "You must be at least 18 years old": "You must be at least 18 years old",
  'Estamos realizando uma validação, assim que estiver tudo ok, você receberá um email.': 'We are validating your account, as soon as everything is ok, you will receive an email.',
  "Thank you!": "Thank you!",
  "You will be redirected to the home page in a few seconds.": "You will be redirected to the home page in a few seconds.",
  "As soon as we receive payment confirmation from the Payment Gateway, you will receive an email with the purchase confirmation.": "As soon as we receive payment confirmation from the Payment Gateway, you will receive an email with the purchase confirmation.",
  "If you have any questions, please contact us at": "If you have any questions, please contact us at",
  "Overcame the challenge!": "overcame the challenge!",
 "Users have this badge": "Users have this badge",
 "Users earned this badge": "Users earned this badge",
 "Completed on ": "Completed on ",
 challenge:'Challenges',
 course: "Courses",
 labs: "Labs",
 completed: "Completed",
 "AnotherRunning": "There is another instance running",
 'subscription': 'Subscription',
  'This Walkthrough will cost 90% of the challenge points.': 'This Walkthrough will cost 90% of the challenge points.',
  "The Walkthrough come at a cost." : "The Walkthrough come at a cost.",
  "I don't want the Walkthrough": "I don't want the Walkthrough",
  "Walkthrough Official - Não disponível": "Walkthrough Official - Not available - Coming Soon",
  "Only available to subscribers": "Only available to subscribers",
  "Subscribe" : "Subscribe now",
  "Opss! this is not the correct answer.": "Opss! this is not the correct answer.",
  "Percentual de acerto: ": "Hit percentage: ",
  "Certified": "Certified",
  "Download Certificate": "Download Certificate",
  "Share LinkedIn": "Share LinkedIn",
  "Certificate LinkedIn": "Certificate LinkedIn",
  "Request Certified": "Request Certified",
  "course_imcomplete": "To complete the module, you need to finish all tasks.",
  "modules_to_finish": "Content to finish",
  "EXAM": "Exam",
  "SIMULATED": "Simulated",
  "Percentage of completion": "Percentage of completion",
  "Start the Exam": "Start the Exam",
  "Exam Details": "Exam Details",
  "No time limit": "No time limit",
  "Attempts Allowed": "Attempts Allowed",
  "Additional Information": "Additional Information",
  "No attempt limit": "No attempt limit",
  "Number of Questions": "Number of Questions",
  "Passing Score": "Passing Score",
  "Continue the Exam": "Continue the Exam",
  "Inicia_help": "Date to Start the exam. Period when the exam will be available",
  "finaliza_help": "Date to Finish the exam",
  "question_help": "Number of questions",
  "time_limit_help": "Time limit to finish the exam",
  "attemps_help": "Number of attempts allowed. Attemp is the number of times you can submit the exam",
  "grade_help": "Grade of the exam",
  "score_help": "Minimum score to pass the exam",
  "Grade": "Grade",
  "You do not have permission to take this exam.": "You do not have permission to take this exam.",
  "The exam has not started yet": "The exam has not started yet",
  "The maximum number of attempts has been reached": "The maximum number of attempts has been reached",
   "The exam is closed": "The exam period is closed",
   "Error to Start the Exam": "Error to Start the Exam",
   'Confirm Exam Submission': 'Confirm the Exam Submission',
   'Exam Summary': 'Exam Summary',
   'Total Questions': 'Total Questions',
   'Answered Questions': 'Answered Questions',
   'Unanswered Questions': 'Unanswered Questions',
   'Questions Marked for Review': 'Questions Marked for Review',
   'You have': 'You have',
   'unanswered questions' : 'unanswered questions',
   'Are you sure you want to submit?': 'Are you sure you want to submit?',
   'questions marked for review': 'questions marked for review',
   'Please verify them before submitting': 'Please verify them before submitting',
   'Return to Exam': 'Return to Exam',
   'Confirm Submission': 'Confirm Submission',
   'Exam Submitted Successfully!' : 'Exam Submitted Successfully!',
   'Your exam answers have been recorded': 'Your exam answers have been recorded',
   'Exam Information': 'Exam Information',
   'Discipline': 'Discipline',
   'Content': 'Content',
   'Submission Stats': 'Submission Stats',
   'Your results will be available after grading is complete': 'Your results will be available after grading is complete', 
   'Return to Dashboard': 'Return to Dashboard',
   'View All Exams' : 'View All Exams',
   'Exam Submitted': 'Exam Submitted',
   "Remove Review Mark": "Remove Review Mark",
   "Mark for Review": "Mark for Review",
   "Answer Question": "Answer Question and Go to Next",
   'Select up to 2 options': 'Select up to 2 options',
   'Maximum of 2 choices allowed': 'Maximum of 2 choices allowed',
   "Answered and saved": "Question Answered and Saved",
   "Answered": "Answered",
   "Saved": "Saved",
   "Marked": "Marked",
   'Exam Progress': 'Exam Progress',
   'All Questions Completed!' : 'All Questions Completed!',
   'Congratulations! You have answered all questions in this exam. Would you like to submit now or continue reviewing your answers?': 'Congratulations! You have answered all questions in this exam. Would you like to submit now or continue reviewing your answers?',
    'Continue Reviewing': 'Continue Reviewing',
    'Finalize and Submit Exam': 'Finalize and Submit Exam',
    'Questions Saved': 'Questions Saved',
    'Unanswered Questions': 'Unanswered Questions',
    'questions Saved': 'questions saved',
    'unanswered questions': 'unanswered questions',
  };

export default enJSON;
