import Authenticated from "../components/Authenticated";
import BaseLayout from "../layouts/BaseLayout";
import ExtendedSidebarLayout from "../layouts/ExtendedSidebarLayout";
import dashboardsRoutes from "./dashboards";
import subscriptionRoutes from "./subscription";
import managementRoutes from "./management.js";
import accountRoutes from "./account";
import baseRoutes from "./base";
import { WebsocketProvider } from "../contexts/WebSocketContext";
import ctf_event_routes from "./ctf_events";
import exam_routes from "./exam.js";



const router = [
  {
    path: "account",
    children: accountRoutes,
  },
  {
    path: "",
    element: <BaseLayout />,
    children: baseRoutes,
  },
  {
    path: "",
    element: (
      <Authenticated>
        <WebsocketProvider>
          <ExtendedSidebarLayout />
        </WebsocketProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "dashboards",
        children: dashboardsRoutes,
      },
      {
        path: "ctfevents",
        children: ctf_event_routes,
      },
      {
        path: "exams",
        children: exam_routes,
      },
      {
        path: "subscription",
        children: subscriptionRoutes,
      },
      {
        path: "management",
        children: managementRoutes,
      },
    ],
  },
  
];

export default router;
