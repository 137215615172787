import { Suspense, lazy } from "react";

import SuspenseLoader from "../components/SuspenseLoader";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Exam = Loader(lazy(() => import("../content/dashboards/Exam")));

const ExamApp = Loader(lazy(() => import("../content/dashboards/Exam/single/examapp")));

const DetailExam = Loader(
  lazy(() => import("../content/dashboards/Exam/single"))
);

const SubmissionSuccessWrapper = Loader(
  lazy(() => import("../content/dashboards/Exam/single/examapp/SubmissionSuccessWrapper"))
);


const exam_routes = [
  {
    path: "list",
    children: [
      {
        path: "",
        element: <Exam />,
      },],
    },
    {
    path: "detail",
    children: [
          {
            path: ":examID",
            element: <DetailExam />,
          },
        ],
      },
    {
      path: 'exampp/:uuid/:examAttID',
      element: <ExamApp/>
    },
    {
      path: 'exam/submission-success',
      element: <SubmissionSuccessWrapper />
    }
    
    
    

];

export default exam_routes;
