const esJSON = {
  Learning: "Aprendizaje",
  Challenges: "Desafíos",
  Courses: "Cursos",
  Blueprints: "Planos",
  "Extended Sidebar": "Barra lateral extendida",
  "Accent Sidebar": "Barra lateral de acento",
  "Accent Header": "Encabezado de acento",
  "Boxed Sidebar": "Barra lateral en caja",
  "Collapsed Sidebar": "Barra lateral contraída",
  "Bottom Navigation": "Navegación inferior",
  "Top Navigation": "Navegación superior",
  "Lead Developer": "Desarrollador principal",
  "Mega menu": "Mega menú",
  Doctors: "Doctores",
  Reports: "Informes",
  "Custom dashboard built using the included components":
    "Panel de control personalizado construido con los componentes incluidos",
  Hospital: "Hospital",
  Export: "Exportar",
  "Last year": "El año pasado",
  Expenses: "Gastos",
  Products: "Productos",
  Statistics: "Estadísticas",
  General: "General",
  Automation: "Automatización",
  "Data Display": "Visualización de datos",
  Calendar: "Calendario",
  Mailbox: "Buzón",
  Dashboards: "Cuadros de mando",
  Analytics: "Analítica",
  Banking: "Bancario",
  Commerce: "Comercio",
  Crypto: "Cripto",
  Finance: "Finanzas",
  Fitness: "Aptitud física",
  Healthcare: "Cuidado de la salud",
  "Doctors Page": "Página de médicos",
  "Hospital Overview": "Descripción del hospital",
  Helpdesk: "Mesa de ayuda",
  Learning: "Aprendiendo",
  Monitoring: "Supervisión",
  Tasks: "Tareas",
  Applications: "Aplicaciones",
  "File Manager": "Administrador de archivos",
  "Jobs Platform": "Plataforma de trabajos",
  Messenger: "Mensajero",
  "Projects Board": "Junta de proyectos",
  Management: "Administración",
  Users: "Usuarios",
  "List All": "Listar todo",
  "User Profile": "Perfil del usuario",
  Projects: "Proyectos",
  Shop: "Tienda",
  "Products List": "Lista de productos",
  "View Product": "Ver el producto",
  "Create Product": "Crear producto",
  Invoices: "Facturas",
  "View Single": "Ver individual",
  "Auth Pages": "Autenticación",
  Login: "Acceso",
  Basic: "Básico",
  Cover: "Cubrir",
  Register: "Registrarse",
  Wizard: "Mago",
  "Recover Password": "Recuperar contraseña",
  Status: "Estado",
  Foundation: "Fundación",
  "Extra Pages": "Páginas extra",
  "Error 404": "Error 404",
  "Error 500": "Error 500",
  "Coming Soon": "Próximamente",
  Maintenance: "Mantenimiento",
  Overview: "Visión general",
  "Layout Starters": "Arrancadores de diseño",
  "Starter Kit 1": "Kit de inicio 1",
  "Starter Kit 2": "Kit de inicio 2",
  Documentation: "Documentación",
  Welcome: "Bienvenida",
  Help: "Ayuda",
  "Features tour": "Tour de características",
  "Getting started guide": "Guía de inicio",
  "Contact support": "Soporte de contacto",
  Version: "Versión",
  Search: "Buscar",
  Notifications: "Notificaciones",
  Settings: "Ajustes",
  "Language Switcher": "Selector de idioma",
  "Sign out": "Desconectar",
  "Change Theme": "Cambiar de tema",
  "View all notifications": "Ver todas las notificaciones",
  "Tokyo NextJS Typescript Admin Dashboard":
    "Panel de administración de Tokyo React",
  "High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes":
    "Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos",
  "Browse Live Preview": "Explorar vista previa en vivo",
  "Live Preview": "Vista previa en vivo",
  "Key Features": "Características clave",
  dashboards: "cuadros de mando",
  applications: "aplicaciones",
  "management sections": "secciones de gestión",
  "dark/light themes": "temas oscuros / claros",
  components: "componentes",
  "Some of the features that make Tokyo one of the best admin templates available today":
    "Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad",
  "Design Source Files": "Diseñar archivos fuente",
  "Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first":
    "Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero",
  "We only translated a small part of the template, for demonstration purposes":
    "Solo traducimos una pequeña parte de la plantilla, con fines de demostración",
  Challenge: "Desafío",
  "Find the plan that suits you best":
    "Encuentre el plan que mejor se adapte a usted",
  "Choose your plan": "Elige tu plan",
  month: "mes",
  year: "año",
  Features: "Caracteristicas",
  Monthly: "Mensual",
  Annual: "Anual",
  "Assinando Agora você ganha 20% de desconto no primeiro mês":
    "Al suscribirse ahora, obtiene un 20% de descuento en el primer mes",
  "Select Plan": "Seleccionar plan",
  "Your request has been sent to the team captain":
    "Su solicitud ha sido enviada al capitán del equipo",
  "Additional Informations": "Informaciones adicionales",
  Reviews: "Comentarios",
  "Challenge Details": "Detalles del desafío",
  "total challenges": "desafíos totales",
  "VPN Server": "Servidor VPN",
  "Generate VPN Profile": "Generar Perfil VPN",
  "Download here to connect to the VPN":
    "Descargue aquí para conectarse a la VPN",

  "Courses Enrolled Completed": "Cursos Inscritos Completados",
  Points: "Puntos",
  "Points to ": "Puntos para ",
  "Challenges Accepted Completed": "Desafíos Completados",
  Achieved: "Alcanzado",
  "First Blood Achieved":
    "Número de veces que fuiste el primero en resolver un desafío o prueba",
  "Course points": "Puntos de curso",
  "Challenges points": "Puntos de desafíos",
  "My Ranking": "Mi clasificación",
  Leaderboard: "Top 10",
  Ranking: "Rank",
  Member: "Partícipe",
  "Points to reach the next level": "Puntos para alcanzar el siguiente nivel",
  "Percentual of courses completed": "Porcentaje de cursos completados",
  "Percentual of challenges completed": "Porcentaje de desafíos completados",
  "Total points earned from courses and challenges":
    "Total de puntos ganados con cursos y desafíos",
  "Total points earned from courses": "Total de puntos ganados con cursos",
  "Total points earned from challenges": "Total de puntos ganados con desafíos",
  "Find your challenge, and learn something new!":
    "¡Encuentra tu desafío y aprende algo nuevo!",
  level: "Nivel",
  Level: "Nivel",
  title: "Título",
  "Sort by...": "Ordenar por...",
  Showing: "Mostrando",
  of: "de",
  challenges: "desafíos",
  "Unlock Your Potential": "Resolver agora",
  "Rows per page": "Elementos por página",
  "Search Challenges": "Busca desafíos de ciberseguridad con IA",
  Verifying: "Verificando",
  Running: "Ejecutando",
  Stopped: "Parado",
  "Pronto Para Iniciar": "Listo para comenzar",
  "Parando...": "Parando...",
  "Inicializando...": "Inicializando...",
  "Realize o Download do Arquivo para encontrar a Flag":
    "Realice la descarga del archivo para encontrar la Flag",
  "Students Reviews": "Reseñas",
  "Leave your review about the Challenge": "Deja tu reseña sobre el desafío",
  Submit: "Enviar",
  "Thanks for your review!": "¡Gracias por tu reseña!",
  "Verified Reviewer": "Reseña Verificada",
  Instructions: "Instrucciones",
  "Category:": "Categoría:",
  "I accept this Challenge": "¡Atrévete y Supera tus Límites!",
  "Access to Lab here": "Acceso al Lab aquí",
  Expires: "Termina em",
  "My Learning": "Mi aprendizaje",
  "My Courses and Challenges!": "¡Mis cursos y desafíos!",
  "Search Courses or Challenges": "Buscar cursos o desafíos",
  "Matricule-se no curso.": "Inscríbete en el curso.",
  "Conteúdo do curso": "Contenido del curso",
  Outcomes: "Resultados",
  Content: "Contenido",
  Enroll: "Inscribirse",
  "Course Details": "Detalles del curso",
  Hint: "Pista",
  "The tips come at a cost.": "Las pistas tienen un costo.",
  "This tip will cost": "Esta pista costará",
  "I Accept": "Acepto",
  "Congratulations! Correct answer": "¡Felicidades! Respuesta correcta",
  "Your download is ready!": "¡Tu descarga está lista!",
  "Congratulations! You have completed the last module of the course.":
    "¡Felicidades! Has completado el último módulo del curso.",
  "Leave your review about the Course.": "Deja tu reseña sobre el curso.",
  courses: "Cursos",
  "Search Courses": "Buscar cursos",
  "Find your Course": "¡Encuentra tu curso",
  "Start to Learn Now": "Comienza a aprender ahora",
  "CTF Events": "Eventos CTF",
  "'Let's having fun!'": "'¡Divirtámonos!'",
  Thanks: "Gracias",
  "Search CTF": "Buscar eventos CTF",
  "total Events": "eventos totales",
  "Inscreva-se": "Inscríbete",
  "Thanks you for participating": "¡Gracias por participar!",
  "Remaining Time": "Tiempo restante",
  Prizes: "Premios",
  Audience: "Público",
  Organization: "Organización",
  "Visit Website": "Visitar sitio web",
  Sponsor: "Patrocinador",
  "Signup the CTF": "Inscríbete en el CTF",
  "Organizated by": "Organizado por",
  "Only Captains can signup the team": "Solo los capitanes pueden inscribirse",
  "Bellow options are the teams that you are captain":
    "Estos son los equipos de los que eres capitán",
  Cancel: "Cancelar",
  Save: "Guardar",
  "Back to CTF Events": "Volver a los eventos CTF",
  Teams: "Equipos",
  "CTF Teams": "Equipos CTF",
  "Create Team": "Crear equipo",
  "Fill in the fields below to create a Team":
    "Complete los campos a continuación para crear un equipo",
  "The name field is required": "El campo nombre es obligatorio",
  "The lema name field is required": "El campo lema es obligatorio",
  "The Team has been removed": "El equipo ha sido eliminado",
  "Erro to remove Team": "Error al eliminar el equipo",
  "Your request has been sent to the team captain":
    "Tu solicitud ha sido enviada al capitán del equipo",
  "We couldn't find any teams matching your search criteria":
    "No pudimos encontrar ningún equipo que coincida con tus criterios de búsqueda",
  Name: "Nombre",
  Captain: "Capitán",
  Players: "Jugadores",
  "Ask to Join": "Solicitar unirse",
  "Are you sure you want to permanently delete this Team": "¿Estás seguro?",
  Delete: "Eliminar",
  "Users - Profile": "Perfil de usuario",
  "Back to Teams": "Volver a los equipos",
  Details: "Detalles",
  Role: "Rol",
  "Join Requests": "Solicitudes de unión",
  Settings: "Configuración",
  "Manage informations related to your Team":
    "Administrar información relacionada con tu equipo",
  "Team Settings": "Configuración del equipo",
  "Any Join Request found": "No se encontraron solicitudes de unión",
  Profile: "Perfil",
  "Edit Profile": "Editar perfil",
  "Access Logs": "Registros de acceso",
  "Recent sign in activity logs":
    "Registros de actividad de inicio de sesión recientes",
  "I don't want the tip": "No quiero la pista",
  "Enjoy your tip": "Disfruta tu pista",
  True: "Verdader",
  False: "Falso",
  "Execute the task and click Complete":
    "Ejecuta la tarea y haz clic en Completar",
  Complete: "Completar",
  Theme: "Tema",
  "Profile settings": "Config. perfil",
  "PowerOn/PowerOff": "Iniciar/Desligar",
  'Revert': 'Revertir',
  "Challenge IP": "IP del desafío",
  "Access ExtremeBox here": "Acceso a ExtremeBox aquí",
  "Em Breve": "Próximamente",
  "Ranking_board": "Clasificación",
  "Team": "Equipo",
  "The tips come at a cost.": "Las pistas tienen un costo.",
  "This tip will cost": "Esta pista costará",
  "I don't want the tip": "No quiero la pista",
  "Enjoy your tip": "Disfruta tu pista",
  "This tip will cost": "Esta pista costará",
  "Event has ended.": "El evento ha terminado.",
  "Register": "Registrarse",
  "Regras": "Reglas",
  "User": "Usuario",
  "Registered at the event with": "Registrado en el evento con",
  "Start Date": "Fecha de inicio",
  "End Date": "Fecha de finalización",
  "Revert the machine to prestine state": "Revertir la máquina al estado prístino",
  'Birth date': 'Fecha de nacimiento',
  'Birth date is required': 'Fecha de nacimiento es obligatoria',
  'You must be at least 18 years old': 'Debes tener al menos 18 años',
  "Estamos realizando uma validação, assim que estiver tudo ok, você receberá um email.": "Estamos realizando una validación, tan pronto como todo esté bien, recibirás un correo electrónico.", 
  "Thank you!": "¡Gracias!",
  "You will be redirected to the home page in a few seconds.": "Serás redirigido a la página de inicio en unos segundos.",
  "As soon as we receive payment confirmation from the Payment Gateway, you will receive an email with the purchase confirmation.": "Tan pronto como recibamos la confirmación de pago de la pasarela de pago, recibirá un correo electrónico con la confirmación de compra.",
  "If you have any questions, please contact us at": "Si tiene alguna pregunta, comuníquese con nosotros en",
  "Overcame the challenge!": "¡superó el desafío!",
  "Users have this badge": "Los usuarios tienen esta insignia",
  "Users earned this badge": "Los usuarios ganaron esta insignia",
  "Completed on ": "Completado el ",
  challenge: "Desafíos",
  course: "Cursos",
  lab: "Laboratorios",
  completed: "Completado",
  "AnotherRunning": "Otra instancia en ejecución",
  "subscription": "Suscripción",
  "I accept the": "He leído y acepto los",
  'This Walkthrough will cost 90% of the challenge points.': 'Este Walkthrough costará el 90% de los puntos del desafío.',
  "The Walkthrough come at a cost." : "El Walkthrough tiene un costo.",
  "I don't want the Walkthrough": "No quiero el Walkthrough",
  "Walkthrough Official - Não disponível": "Walkthrough Oficial - No disponible - Em Breve",
  "Only available to subscribers": "Solo disponible para suscriptores",
  "Subscribe" : "Assine Agora",
  "Opss! this is not the correct answer.": "¡Opss! esta no es la respuesta correcta.",
  "Percentual de acerto: ": "Porcentaje de acierto: ",
  "Certified": "Certificado",
 "Download Certificate": "Descargar Certificado",
  "Share LinkedIn": "Compartir LinkedIn",
  "Certificate LinkedIn": "Certificado LinkedIn",
  "Request Certified": "Solicitar Certificado",
  "course_imcomplete": "Para concluir el módulo, es necesario completar todas las tareas",
  "modules_to_finish": "Conteudos para terminar",
  "Level 1-5": "Nivel 1-5",
  "Level 5-1": "Nivel 5-1",
  "Title A-Z": "Título A-Z",
  "Title Z-A": "Título Z-A",
  "Completed A-Z": "Completado A-Z",
  "Completed Z-A": "Completado Z-A",
  "EXAM": "Examen",
  "SIMULATED": "Simulado",
  "Percentage of completion": "Porcentaje de completitud",
  'Start the Exam': 'Iniciar el examen',
  "Exam Details": "Detalles del examen",
  "No time limit": "Sin límite de tiempo",
  "Attempts Allowed": "Intentos permitidos",
  "Additional Information": "Información adicional",
  "No attempt limit": "Sin límite de intentos",
  "Number of Questions": "Número de preguntas",
  "Passing Score": "Puntuación de aprobación",
  "Continue the Exam": "Continuar el examen",
  "Inicia_help": "Fecha y ora de inicio del examen. Puede iniciar el examen en cualquier momento antes de la fecha de finalización.",
  "finaliza_help": "Fecha y hora de finalización del examen",
  "question_help": "Número de preguntas del examen",
  "time_limit_help": "Duración del examen",
  "attemps_help": "Número de intentos permitidos",
  "grade_help": "Nota máxima del examen",
  "score_help": "Nota mínima para aprobar",
  "Grade": "Nota",
  "You do not have permission to take this exam." : "No tienes permiso para realizar este examen.",
  "The exam has not started yet" : "El examen aún no ha comenzado",
  "The maximum number of attempts has been reached": "Se ha alcanzado el número máximo de intentos",
   "The exam is closed": "El período de examen ha finalizado",
   "Error to Start the Exam": "Error al iniciar el examen",
  'Confirm Exam Submission': 'Confirme envío del examen',
  'Exam Summary': 'Resumen del examen',
  'Total Questions': 'Total de preguntas',
  'Answered Questions': 'Preguntas respondidas',
  'Unanswered Questions': 'Preguntas no respondidas',
  'Questions Marked for Review': 'Preguntas marcadas para revisión',
  'You have': 'Tienes',
  'Are you sure you want to submit?' : '¿Estás seguro de que quieres enviar?',
  'questions marked for review' : 'preguntas marcadas para revisión',
  'Please verify them before submitting' : 'Por favor, verifíquelos antes de enviar',
  'Return to Exam': 'Volver al examen',
  'Confirm Submission': 'Confirmar envío',
  'Exam Submitted Successfully!' : '¡Examen enviado con éxito!',
  'Your exam answers have been recorded' : 'Tus respuestas de examen han sido registradas',
  'Exam Information' : 'Información del examen',
  'Discipline'  : 'Disciplina',
  'Content' : 'Contenido',
  'Submission Stats' : 'Estadísticas de envío',
  'Your results will be available after grading is complete' : 'Tus resultados estarán disponibles después de que se complete la calificación',
  'Return to Dashboard' : 'Volver al panel de control',
  'Exam Results' : 'Resultados del examen',
  'Exam Submitted' : 'Examen enviado',
  "Remove Review Mark": "Eliminar marca de revisión",
   "Mark for Review": "Marcar para revisión",
   "Answer Question": "Responder pregunta e ir a la siguiente",
   'Select up to 2 options': 'Selecciona hasta 2 opciones',
   'Maximum of 2 choices allowed': 'Máximo de 2 opciones permitidas',
   "Answered and saved": "Respondido y salvado",
   "Answered": "Respondido",
   "Saved": "Salvado",
   "Marked": "Marcado",
   'Exam Progress': 'Progreso del examen',
   'All Questions Completed!' : '¡Todas las preguntas completadas!',
   'Congratulations! You have answered all questions in this exam. Would you like to submit now or continue reviewing your answers?': '¡Felicidades! Has respondido todas las preguntas de este examen. ¿Te gustaría enviar ahora o seguir revisando tus respuestas?',
  'Continue Reviewing': 'Continuar revisando',
  'Finalize and Submit Exam': 'Finalizar y enviar examen',
  'Questions Saved': 'Preguntas guardadas',
  'Unanswered Questions': 'Preguntas sin respuesta',
  'questions Saved': 'preguntas guardadas',
  'unanswered questions': 'preguntas sin respuesta',
};

export default esJSON;
