import SmartToyTwoToneIcon from "@mui/icons-material/SmartToyTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import SportsScoreTwoToneIcon from "@mui/icons-material/SportsScoreTwoTone";
import StarsTwoToneIcon from "@mui/icons-material/StarsTwoTone";
import IconDiscord from "../../../../components/IconDiscord";
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';

const menuItems = [
  {
    heading: "Aprendizado",
    order: 1,
    items: [
      {
        open: true,
        active: true,
        name: "Dashboards",
        icon: SmartToyTwoToneIcon,
        link: "/dashboards",
      },
      {
        name: "My Learning",
        link: "dashboards/mylearning",
        icon: StarsTwoToneIcon,
      },
      {
        name: "Cursos",
        icon: SchoolTwoToneIcon,
        link: "/dashboards/course",
      },]},

      {
        heading: "CYBERARENA",
        order: 2,
        icon: SportsScoreTwoToneIcon,
        link: "",
        open: true,
        order: 1,
        items: [
          {
            name: "Desafios",
            open: true,
            icon: SportsScoreTwoToneIcon,
            link: "/dashboards/challenge",
          },
          {
            name: "Exames",
            open: true,
            icon: SportsScoreTwoToneIcon,
            link: "/exams/list",
          },
         
        ],
      },
      
      {
        heading: "EVENTS",
        order: 3,
        items: [
          {
            name: "CTF",
            link: "/ctfevents/event",
          },
          
        ],
      },
      {
        heading: "HELP",
        order: 5,
             items: [
               
               {
                 name: "Discord",
                 icon: IconDiscord,
                 link: "https://discord.gg/BHMC5NsPMp",
                 external: true,
               },
               {
                 name: "Suporte",
                 icon: SupportAgentTwoToneIcon,
                 link: "/account/support",
               },
             ],
           }
         
     
];

export default menuItems;
